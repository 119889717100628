<template>
  <v-navigation-drawer
    v-bind="$attrs"
    class="drawer"
    disable-route-watcher
    location="right"
    order="-1"
    tag="div"
    temporary
    width="720"
    @update:model-value="$emit('close')"
  >
    <div class="d-flex drawer-header align-center justify-space-between bg-background px-6">
      <div class="qtm-h1 text-secondary text-uppercase">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <v-hover v-slot="{ isHovering }">
        <qtm-icon-btn :color="isHovering ? 'interactive' : 'mid-grey'" icon="mdi-close" @click="close" />
      </v-hover>
    </div>
    <div class="drawer-content" :class="{ 'drawer-content--no-footer': noFooter }">
      <slot />
    </div>
    <div v-if="!noFooter" class="bg-white drawer-footer d-flex align-center justify-space-between pa-8">
      <div>
        <slot name="actions-left" />
      </div>
      <div>
        <slot name="actions" />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
export interface Props {
  noFooter?: boolean
  title?: string
}

withDefaults(defineProps<Props>(), {
  title: '',
})
const emit = defineEmits(['close', 'update:model-value'])

const close = () => {
  emit('close')
  emit('update:model-value', false)
}
</script>

<style lang="scss" scoped>
$headerHeight: 64px;
$footerHeight: 105px;
$heightOffset: $headerHeight + $footerHeight;

.drawer-header {
  height: $headerHeight;
  position: absolute;
  width: 100%;
}

.drawer-content {
  height: calc(100vh - #{$heightOffset});
  margin-bottom: $footerHeight;
  margin-top: $headerHeight;
  overflow-y: auto;

  &--no-footer {
    height: calc(100vh - #{$headerHeight});
    margin-bottom: 0;
  }
}

.drawer-footer {
  bottom: 0;
  height: $footerHeight;
  position: absolute;
  width: 100%;
}
</style>
